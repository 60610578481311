import { ClientListHeader } from "../components/atoms/ClientList/ClientListHeader";
import "./ClientList.css";

import { connect, ConnectedProps } from "react-redux";
import ClientListFilterPreset from "../components/atoms/ClientList/ClientListFilterPreset";
import ClientListGroupMessage from "../components/atoms/ClientList/ClientListGroupMessage";
import Spinner from "../components/atoms/Spinner";
import ClientListTable from "../components/organisms/ClientListTable";
import { clientListSelector } from "../selectors/clientListSelector";
import { ClientListFilterPresetEnum } from "../services/api/clientListApi";
import { AppState } from "../store";
import { useEffect, useState } from "react";


const mapState = (state: AppState) => ({
    clientList: clientListSelector(state),
});

const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

const ClientList = (props: PropsFromRedux) => {
    const [canUseGroupMessageFeature, setCanUseGroupMessageFeature] = useState(false);

    useEffect(() => {
        setCanUseGroupMessageFeature(
            props.clientList.filterPreset === ClientListFilterPresetEnum.RecentlyContacted ||
            props.clientList.filterPreset === ClientListFilterPresetEnum.New ||
            props.clientList.filterPreset === ClientListFilterPresetEnum.Favorite);
    }, [props.clientList.filterPreset])

    return (
        <>
            <ClientListHeader />
            {
                canUseGroupMessageFeature && <ClientListGroupMessage />
            }
            <div className="client-list-continer">
                <div className="filter-preset">
                    <ClientListFilterPreset />
                </div>
                <div className="result">

                    {props.clientList.filterPreset !== ClientListFilterPresetEnum.PotentialClients && <ClientListTable />}
                    {/* temporary removed IM-110 {props.clientList.filterPreset === ClientListFilterPresetEnum.PotentialClients && <PotentialClientsTable />} */}


                    <div className="cl-cover-result" hidden={!props.clientList.isLoadingPageData} >
                        <Spinner size={40} />
                    </div>
                </div>
            </div>
        </>
    )
};

export default connector(ClientList);
